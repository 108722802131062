import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, Icon, Tab, Tabs } from '@mui/material';

import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useMetaAdImages from '@/hooks/meta/useMetaAdImages';
import useMetaAdVideos from '@/hooks/meta/useMetaAdVideos';
import { MetaAdsFormModel } from '@/models/Meta';
import FacebookAPI from '@/network/FacebookAPI';
import { handleApiError } from '@/utility/api';
import { isValidUrl } from '@/utility/validations';

import DesignYourAds from '../../generic-ads-steps/DesignYourAds';

enum MetaDesignTabs {
  SPOTIFY,
  FACEBOOK,
  UPLOAD,
}

const MetaDesignYourAds = ({
  outputSelectedFacebookMediaUrl,
  canStep,
}: {
  outputSelectedFacebookMediaUrl: (url: string) => void;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { account } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { artist } = useArtist();

  const [tabValue, setTabValue] = useState<MetaDesignTabs>(MetaDesignTabs.FACEBOOK);

  const [uploadedImage, setUploadedImage] = useState<string>();
  const [uploadedVideo, setUploadedVideo] = useState<string>();
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formAdAccountId = watch('facebookAdAccount.adAccountId');
  const formGoal = watch('ad.goal');
  const formMedia = watch('ad.media');
  const formLink = watch('ad.content.link');
  const formPrimaryText = watch('ad.content.primaryText');
  const formLinkHeadline = watch('ad.content.linkHeadline');
  const formLinkDescription = watch('ad.content.linkDescription');

  const isValidLink = useMemo(() => !!isValidUrl(formLink), [formLink]);

  const { metaAdImages, metaAdImagesIsLoading, metaAdImagesError, refetchMetaAdImages } = useMetaAdImages({
    adAccountId: formAdAccountId,
  });
  const { metaAdVideos, metaAdVideosIsLoading, metaAdVideosError, refetchMetaAdVideos } = useMetaAdVideos({
    adAccountId: formAdAccountId,
  });

  const uploadImageToFacebook = useCallback(
    async (base64Image: string) => {
      setIsUploading(true);
      try {
        if (!account || !formAdAccountId) return;
        const data = new FormData();
        const accessToken = account.accessTokens.filter((item) => item.platform === 'meta')[0].accessToken;

        data.append('bytes', base64Image.split(',')[1]);
        data.append('access_token', accessToken);

        const response = await FacebookAPI.uploadImage({ adAccountId: formAdAccountId, data });

        const updatedImagesResponse = await refetchMetaAdImages();

        const image = updatedImagesResponse?.data.data.find((item) => item.hash === response.data.images.bytes.hash);
        setIsUploaded(true);

        if (!image) return;

        setValue('ad.media', [image.hash]);
        setValue('ad.type', 'image');
        outputSelectedFacebookMediaUrl(image.url);

        setIsUploaded(true);
        setTabValue(MetaDesignTabs.FACEBOOK);
      } catch (error: unknown) {
        setIsUploaded(false);
        handleApiError({ error, dispatchSnackbar });
      } finally {
        setIsUploading(false);
      }
    },
    [account, dispatchSnackbar, formAdAccountId, outputSelectedFacebookMediaUrl, refetchMetaAdImages, setValue]
  );

  const uploadVideoToFacebook = useCallback(
    async (videoBlob: File) => {
      setIsUploading(true);
      try {
        if (!account || !formAdAccountId) return;
        const accessToken = account.accessTokens.filter((item) => item.platform === 'meta')[0].accessToken;

        const data = new FormData();
        data.append('contentType', 'multipart/form-data');
        data.append('title', videoBlob.name);
        data.append('description', videoBlob.name);
        data.append('file', videoBlob, videoBlob.name);

        const response = await FacebookAPI.uploadVideo({ adAccountId: formAdAccountId, data, accessToken });

        const updatedVideosResponse = await refetchMetaAdVideos();
        const image = updatedVideosResponse?.data.data.find((item) => item.id === response.data.id);
        setIsUploaded(true);

        if (!image) return;

        setValue('ad.media', [image.source]);
        setValue('ad.type', 'video');
        setValue('ad.videoThumbnail', image.thumbnails.data[0].uri);
        outputSelectedFacebookMediaUrl(image.source);

        setIsUploaded(true);
        setTabValue(MetaDesignTabs.FACEBOOK);
      } catch (error: unknown) {
        setIsUploaded(false);
        handleApiError({ error, dispatchSnackbar });
      } finally {
        setIsUploading(false);
      }
    },
    [account, dispatchSnackbar, formAdAccountId, outputSelectedFacebookMediaUrl, refetchMetaAdVideos, setValue]
  );

  const handleImageUploader = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      try {
        if (!event.target.files) return;

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
          const base64Data = reader.result?.toString();
          if (!base64Data) throw Error;

          setUploadedVideo(undefined);
          setUploadedImage(base64Data);
          setIsUploaded(false);
        };

        reader.readAsDataURL(file);
      } catch (error: unknown) {
        handleApiError({ error, dispatchSnackbar });
      }
    },
    [dispatchSnackbar]
  );

  const handleVideoUploader = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      try {
        if (!event.target.files) return;

        const file = event.target.files[0];
        const newFile = new File([file], file.name, { type: 'video/mp4' });
        const reader = new FileReader();

        reader.onload = async () => {
          const base64Data = reader.result?.toString();
          if (!base64Data) throw Error;

          setUploadedImage(undefined);
          setUploadedVideo(base64Data);
          setVideoFile(newFile);
          setIsUploaded(false);
        };

        reader.readAsDataURL(file);
      } catch (error: unknown) {
        handleApiError({ error, dispatchSnackbar });
      }
    },
    [dispatchSnackbar]
  );

  const isImageOrVideo = useCallback(
    (media: ChangeEvent<HTMLInputElement>) => {
      if (!media.target.files) return;
      const file = media.target.files[0];
      if (file.type.includes('image')) return handleImageUploader(media);
      if (file.type.includes('video')) return handleVideoUploader(media);
    },
    [handleImageUploader, handleVideoUploader]
  );

  useEffect(() => {
    canStep(
      !!(
        formMedia.length > 0 &&
        formPrimaryText.length > 0 &&
        formLink.length > 0 &&
        formLinkHeadline.length > 0 &&
        formLinkDescription.length > 0
      )
    );
  }, [
    canStep,
    formLink.length,
    formLinkDescription.length,
    formLinkHeadline.length,
    formMedia.length,
    formPrimaryText.length,
  ]);

  const setArtistLink = useCallback(() => {
    switch (formGoal) {
      case 'more-streams':
        setValue('ad.content.link', artist?.details.externalLinks?.spotify || '');
        break;
      case 'spotify-followers':
        setValue('ad.content.link', artist?.details.externalLinks?.spotify || '');
        break;
      case 'instagram-followers':
        setValue('ad.content.link', artist?.details.externalLinks?.instagram || '');
        break;
      case 'facebook-likes':
        setValue('ad.content.link', artist?.details.externalLinks?.facebook || '');
        break;
      case 'youtube-views':
        setValue('ad.content.link', artist?.details.externalLinks?.youtube || '');
        break;
      default:
        setValue('ad.content.link', '');
        break;
    }
  }, [artist?.details.externalLinks, formGoal, setValue]);

  useEffect(() => {
    setArtistLink();
  }, [setArtistLink, artist]);

  if (!artist) {
    return <Loading size="small" />;
  }

  return (
    <DesignYourAds>
      <div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w50p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-IMAGE')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-IMAGE-DESCRIPTION')}</p>
          </div>
          <div className="flex-w50p w100p-lg-down">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',

                marginBottom: '8px',
              }}
            >
              <Tabs value={tabValue} onChange={(_, value) => setTabValue(value)} aria-label="basic tabs example">
                <Tab label={t('BRAND.FACEBOOK')} value={MetaDesignTabs.FACEBOOK} />
                <Tab label={t('COMMON.UPLOAD')} value={MetaDesignTabs.UPLOAD} />
              </Tabs>
            </Box>
            {tabValue === MetaDesignTabs.FACEBOOK && (
              <div>
                <div className="d-flex jc-space-between mt16 mb16">
                  <h5 className=" text-left">Images</h5>
                  {!metaAdVideosIsLoading && (
                    <Icon
                      onClick={() => {
                        refetchMetaAdImages();
                      }}
                    >
                      refresh
                    </Icon>
                  )}
                </div>
                {metaAdImagesIsLoading && <Loading size="small" />}
                {metaAdImagesError && (
                  <div>
                    <p>There was an error loading images.</p>
                  </div>
                )}
                {!metaAdImagesIsLoading && metaAdImages && metaAdImages.length > 0 && (
                  <div className="photo-grid">
                    {metaAdImages.map((item) => (
                      <ButtonComponent
                        isCustom
                        key={item.id}
                        className={`grid-item cursor-pointer`}
                        onClick={() => {
                          setValue('ad.type', 'image');
                          setValue('ad.media', [item.hash]);
                          outputSelectedFacebookMediaUrl(item.url);
                        }}
                      >
                        <img
                          className={`${formMedia[0] === item?.hash ? 'photo-selected' : ''}`}
                          src={item.url}
                          alt=""
                        />
                        {formMedia[0] === item?.hash && <Icon className="text-blue selected-icon">check_circle</Icon>}
                      </ButtonComponent>
                    ))}
                  </div>
                )}
                <div className="d-flex jc-space-between mt16 mb16">
                  <h5 className=" text-left">Videos</h5>
                  {!metaAdVideosIsLoading && (
                    <Icon
                      onClick={() => {
                        refetchMetaAdVideos();
                      }}
                    >
                      refresh
                    </Icon>
                  )}
                </div>
                {metaAdVideosIsLoading && <Loading size="small" />}
                {metaAdVideosError && (
                  <div>
                    <p>There was an error loading videos.</p>
                  </div>
                )}
                {!metaAdVideosIsLoading && metaAdVideos && metaAdVideos.length > 0 && (
                  <div className="photo-grid mt20">
                    {metaAdVideos?.map((item) => (
                      <ButtonComponent
                        isCustom
                        key={item.id}
                        className={`grid-item cursor-pointer`}
                        onClick={() => {
                          setValue('ad.type', 'video');
                          setValue('ad.media', [item.id]);
                          setValue('ad.videoThumbnail', item.thumbnails.data[0].uri);

                          outputSelectedFacebookMediaUrl(item.source);
                        }}
                      >
                        <video
                          onMouseOver={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                            (event.target as HTMLVideoElement).play();
                            (event.target as HTMLVideoElement).controls = true;
                          }}
                          onMouseLeave={(event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                            (event.target as HTMLVideoElement).pause();
                            (event.target as HTMLVideoElement).currentTime = 0;
                            (event.target as HTMLVideoElement).controls = false;
                          }}
                          className={`${formMedia[0] === item?.id ? 'photo-selected' : ''}`}
                          src={item.source}
                        />
                        {formMedia[0] === item?.id && <Icon className="text-blue selected-icon">check_circle</Icon>}
                      </ButtonComponent>
                    ))}
                  </div>
                )}
              </div>
            )}
            {tabValue === MetaDesignTabs.UPLOAD && (
              <div>
                <input id="inputFile" type="file" onChange={isImageOrVideo} multiple={false} />
                {uploadedImage && (
                  <div className="pos-rel mt20">
                    <img className="spotify-photo photo-selected" id="imageTag" src={uploadedImage} alt="" />
                    <Icon className="text-blue selected-icon">check_circle</Icon>
                  </div>
                )}
                {uploadedVideo && (
                  <div>
                    <p className="text-left mt10">Preview</p>
                    <p className="text-faded small text-left">
                      Some video formats may not show a preview (.mov, quicktime)
                    </p>
                    <div className="pos-rel mt10">
                      <video
                        className="spotify-photo photo-selected"
                        src={uploadedVideo}
                        id="videoTag"
                        controls
                        autoPlay={true}
                      ></video>
                      <Icon className="text-blue selected-icon">check_circle</Icon>
                    </div>
                  </div>
                )}
                {(uploadedImage || uploadedVideo) && (
                  <Button
                    disabled={isUploading || isUploaded}
                    className="btn-white"
                    onClick={() => {
                      if (uploadedImage) return uploadImageToFacebook(uploadedImage);
                      if (videoFile) return uploadVideoToFacebook(videoFile);
                    }}
                  >
                    <span className="pr8">{t('COMMON.UPLOAD')}</span>
                    {!isUploading && !isUploaded && <Icon className="material-symbols-outlined">upload</Icon>}
                    {isUploading && <CircularProgress />}
                    {isUploaded && <Icon className="material-symbols-outlined">check_circle</Icon>}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w50p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.FILL-IN-DETAILS')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.FILL-IN-DETAILS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w50p w100p-lg-down">
            <form>
              <div className="pos-rel">
                <textarea
                  onChange={(event) => setValue('ad.content.primaryText', event.target.value)}
                  value={formPrimaryText}
                  maxLength={200}
                  placeholder={t('SOCIAL-ADS.PRIMARY-TEXT-PLACEHOLDER')}
                />
                <p className="small text-faded textarea-counter">{formPrimaryText.length}/200</p>
              </div>
              <input
                className={`mt8 ${formLink && !isValidLink && 'error-outline'}`}
                placeholder={t('SOCIAL-ADS.LINK-PLACEHOLDER')}
                onChange={(event) => setValue('ad.content.link', event.target.value)}
                value={formLink}
              />
              {formLink && !isValidLink && <p className="small text-error text-left">Please enter a valid link</p>}
              <input
                className="mt8 "
                onChange={(event) => setValue('ad.content.linkHeadline', event.target.value)}
                value={formLinkHeadline}
                placeholder={t('SOCIAL-ADS.LINK-HEADLINE-PLACEHOLDER')}
              />
              <input
                className="mt8 "
                onChange={(event) => setValue('ad.content.linkDescription', event.target.value)}
                value={formLinkDescription}
                placeholder={t('SOCIAL-ADS.LINK-DESCRIPTION-PLACEHOLDER')}
              />
            </form>
          </div>
        </div>
      </div>
    </DesignYourAds>
  );
};

export default MetaDesignYourAds;
