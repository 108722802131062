import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  Icon,
  Slider,
  SliderThumb,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import { advertGenders, genderMap, inverseGenderMap } from '@/constants/Advert';
import { CountryNameConverter } from '@/formatters/CountryNameConverter';
import useAccountContext from '@/hooks/context/useAccountContext';
import useMetaRecommendedInterests from '@/hooks/meta/useMetaRecommendedInterests';
import useMetaSearchInterestOptions from '@/hooks/meta/useMetaSearchInterestOptions';
import useMetaSearchLanguageOptions from '@/hooks/meta/useMetaSearchLanguageOptions';
import useMetaSearchLocationOptions from '@/hooks/meta/useMetaSearchLocationOptions';
import useMetaTargetingSuggestions from '@/hooks/meta/useMetaTargetingSuggestions';
import {
  MetaAdsFormModel,
  MetaAdsInterestsModel,
  MetaAdsLanguagesSearchDataModel,
  MetaAdsLocationModel,
  MetaTargetingAudiencesModel,
  PredeterminedLocations,
} from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';
import { handleApiError } from '@/utility/api';

import AudienceTargeting from '../../generic-ads-steps/AudienceTargeting';

const primaryCountries = ['US', 'JP', 'GB', 'DE', 'FR', 'CA', 'AU', 'NL'];

const MetaLocationComponent = ({
  predeterminedLocations,
  targetingSuggestions,
  targetingIsLoading,
  setPredeterminedLocations,
}: {
  predeterminedLocations: PredeterminedLocations;
  targetingSuggestions?: MetaAdsLocationModel[];
  targetingIsLoading?: boolean;
  setPredeterminedLocations: Dispatch<SetStateAction<PredeterminedLocations>>;
}) => {
  const { t } = useTranslation();

  const [locationSearchValue, setLocationSearchValue] = useState<string>('');
  const [locationAutoCompleteKey, setLocationAutoCompleteKey] = useState<string>(uuidv4());
  const [locationAccordionExpanded, setLocationAccordionExpanded] = useState<boolean>(true);

  const [languageSearchValue, setLanguageSearchValue] = useState<string>('');
  const [languageAutoCompleteKey, setLanguageAutoCompleteKey] = useState<string>(uuidv4());

  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formAudiences = watch('ad.targeting.audiences');
  const formCountries = watch('ad.targeting.geoLocations.countries');
  const formCities = watch('ad.targeting.geoLocations.cities');
  const formRegions = watch('ad.targeting.geoLocations.regions');

  const { searchLocationOptions, searchLocationOptionsIsLoading, refetchSearchLocationOptions } =
    useMetaSearchLocationOptions({
      search: locationSearchValue,
    });
  const { searchLanguageOptions, searchLanguageOptionsIsLoading, refetchSearchLanguageOptions } =
    useMetaSearchLanguageOptions({
      search: languageSearchValue,
    });

  const chosenLanguages = useMemo(() => formAudiences.filter((item) => item.type === 'locales'), [formAudiences]);

  useEffect(() => {
    if (chosenLanguages.length > 0 || predeterminedLocations === PredeterminedLocations.NONE) {
      setLocationAccordionExpanded(true);
    }
  }, [chosenLanguages.length, predeterminedLocations]);

  const handleResetLocationAutocomplete = useCallback(async () => {
    setLocationSearchValue('');
    setLocationAutoCompleteKey(uuidv4());
    await refetchSearchLocationOptions(undefined);
  }, [refetchSearchLocationOptions]);

  const handleResetLanguageAutocomplete = useCallback(async () => {
    setLanguageSearchValue('');
    setLanguageAutoCompleteKey(uuidv4());
    await refetchSearchLanguageOptions(undefined);
  }, [refetchSearchLanguageOptions]);

  const handleUpdateLocation = useCallback(
    async (value: MetaAdsLocationModel) => {
      if (predeterminedLocations !== PredeterminedLocations.NONE) {
        setPredeterminedLocations(PredeterminedLocations.NONE);
        switch (value.type) {
          case 'country':
            setValue('ad.targeting.geoLocations.countries', [value.key]);
            setValue('ad.targeting.geoLocations.countryGroups', []);

            break;
          case 'region':
            setValue('ad.targeting.geoLocations.countries', []);
            setValue('ad.targeting.geoLocations.regions', [{ name: value.name, key: value.key }]);
            setValue('ad.targeting.geoLocations.countryGroups', []);
            break;
          case 'city':
            setValue('ad.targeting.geoLocations.countries', []);
            setValue('ad.targeting.geoLocations.cities', [{ name: value.name, key: value.key }]);
            setValue('ad.targeting.geoLocations.countryGroups', []);
            break;
        }
      } else {
        switch (value.type) {
          case 'country':
            setValue('ad.targeting.geoLocations.countries', [...formCountries, value.key]);
            setValue('ad.targeting.geoLocations.countryGroups', []);
            break;
          case 'region':
            setValue('ad.targeting.geoLocations.regions', [...formRegions, { name: value.name, key: value.key }]);
            setValue('ad.targeting.geoLocations.countryGroups', []);
            break;
          case 'city':
            setValue('ad.targeting.geoLocations.cities', [...formCities, { name: value.name, key: value.key }]);
            setValue('ad.targeting.geoLocations.countryGroups', []);
            break;
        }
      }

      await handleResetLocationAutocomplete();
    },
    [
      formCities,
      formCountries,
      formRegions,
      handleResetLocationAutocomplete,
      predeterminedLocations,
      setPredeterminedLocations,
      setValue,
    ]
  );

  const setLocationData = useCallback(
    (locations: MetaAdsLocationModel[]) => {
      const countryGroups = locations.filter((location) => location.type === 'countryGroup');
      const countries = locations.filter((location) => location.type === 'country');
      const cities = locations.filter((location) => location.type === 'city');
      const regions = locations.filter((location) => location.type === 'region');

      setValue(
        'ad.targeting.geoLocations.countryGroups',
        countryGroups.flatMap((country) => country.name)
      );
      setValue(
        'ad.targeting.geoLocations.countries',
        countries.flatMap((country) => country.country_code)
      );
      setValue('ad.targeting.geoLocations.cities', cities);
      setValue('ad.targeting.geoLocations.regions', regions);
    },
    [setValue]
  );

  useEffect(() => {
    if (!targetingSuggestions || !locationAccordionExpanded) return;
    setPredeterminedLocations(PredeterminedLocations.NONE);
    setLocationData(targetingSuggestions);
  }, [locationAccordionExpanded, setLocationData, setPredeterminedLocations, targetingSuggestions]);

  useEffect(() => {
    if (predeterminedLocations !== PredeterminedLocations.NONE && !targetingIsLoading) {
      setLocationAccordionExpanded(false);
    }
  }, [predeterminedLocations, targetingIsLoading]);

  const handleRemoveLocation = useCallback(
    async (value: string, type: 'country' | 'region' | 'city') => {
      switch (type) {
        case 'country':
          setValue(
            'ad.targeting.geoLocations.countries',
            formCountries.filter((country) => country !== value)
          );
          break;
        case 'region':
          setValue(
            'ad.targeting.geoLocations.regions',
            formRegions.filter((region) => region.key !== value)
          );
          break;
        case 'city':
          setValue(
            'ad.targeting.geoLocations.cities',
            formCities.filter((city) => city.key !== value)
          );
          break;
      }
    },
    [formCities, formCountries, formRegions, setValue]
  );

  useEffect(() => {
    if (predeterminedLocations !== PredeterminedLocations.NONE) return;

    if (!formCountries.length && !formRegions.length && !formCities.length) {
      setPredeterminedLocations(PredeterminedLocations.PRIMARY);
    }
  }, [formCities.length, formCountries.length, formRegions.length, predeterminedLocations, setPredeterminedLocations]);

  useEffect(() => {
    switch (predeterminedLocations) {
      case PredeterminedLocations.PRIMARY:
        setValue('ad.targeting.geoLocations.countries', primaryCountries);
        setValue('ad.targeting.geoLocations.regions', []);
        setValue('ad.targeting.geoLocations.cities', []);
        setValue('ad.targeting.geoLocations.countryGroups', []);
        break;
      case PredeterminedLocations.WORLDWIDE:
        setValue('ad.targeting.geoLocations.countryGroups', ['worldwide']);
        setValue('ad.targeting.geoLocations.countries', []);
        setValue('ad.targeting.geoLocations.regions', []);
        setValue('ad.targeting.geoLocations.cities', []);
        break;
      case PredeterminedLocations.NONE:
        break;
      default: {
        const _exhaustiveCheck: never = predeterminedLocations;
        return _exhaustiveCheck;
      }
    }
  }, [predeterminedLocations, setValue]);

  useEffect(() => {
    if (locationSearchValue.length) {
      refetchSearchLocationOptions();
    }
  }, [locationSearchValue, refetchSearchLocationOptions]);

  useEffect(() => {
    if (languageSearchValue.length) {
      refetchSearchLanguageOptions();
    }
  }, [languageSearchValue, refetchSearchLanguageOptions]);

  const handleUpdateLanguage = useCallback(
    async (value: MetaAdsLanguagesSearchDataModel) => {
      setValue('ad.targeting.audiences', [...formAudiences, { id: value.key, name: value.name, type: 'locales' }]);
      await handleResetLanguageAutocomplete();
    },
    [formAudiences, handleResetLanguageAutocomplete, setValue]
  );

  const handleRemoveLanguage = useCallback(
    (value: MetaTargetingAudiencesModel) => {
      setValue(
        'ad.targeting.audiences',
        formAudiences.filter((audience) => audience.id !== value.id)
      );
    },
    [formAudiences, setValue]
  );

  if (targetingIsLoading) {
    return <Loading size="small" />;
  }

  return (
    <>
      <ButtonComponent
        isCustom
        className={`card-inner p16 d-flex gap20 text-left cursor-pointer w100p ${
          predeterminedLocations === PredeterminedLocations.PRIMARY && 'chosen-goal'
        }`}
        onClick={() => setPredeterminedLocations(PredeterminedLocations.PRIMARY)}
      >
        <img className="platform-icon mt-auto mb-auto" src="/images/logos/music-logo-white.svg" alt="" />
        <div>
          <p>{t('SOCIAL-ADS.PRIMARY-MUSIC-MARKETS')}</p>
          <p className={`${predeterminedLocations !== PredeterminedLocations.PRIMARY && 'text-faded'} small mt-6`}>
            {t('SOCIAL-ADS.PRIMARY-MUSIC-MARKETS-COUNTRIES')}
          </p>
        </div>
      </ButtonComponent>
      <ButtonComponent
        isCustom
        className={`card-inner mt16 p16 d-flex gap20 text-left cursor-pointer w100p ${
          predeterminedLocations === PredeterminedLocations.WORLDWIDE ? 'chosen-goal' : ''
        }`}
        onClick={() => setPredeterminedLocations(PredeterminedLocations.WORLDWIDE)}
      >
        <img className="platform-icon mt-auto mb-auto" src="/images/icons/globe.svg" alt="" />
        <p className="mt-auto mb-auto"> {t('SOCIAL-ADS.GLOBAL')}</p>
      </ButtonComponent>
      <Accordion className="card-inner br16 mt16 p16" expanded={locationAccordionExpanded}>
        <AccordionSummary
          onClick={() => setLocationAccordionExpanded(!locationAccordionExpanded)}
          className="mt0 mb0 d-flex gap20"
          expandIcon={
            <Icon className="accordion-expand-icon text-faded" data-testid="expand-accordion-button">
              expand_more
            </Icon>
          }
        >
          <img className="platform-icon mt-auto mb-auto" src="/images/icons/location.svg" alt="" />
          <p className="mt-auto mb-auto">{t('SOCIAL-ADS.SELECT-CUSTOM-LOCATION')}</p>
        </AccordionSummary>
        <AccordionDetails>
          <p className="text-left mt8 text-faded small">{t('SOCIAL-ADS.SELECT-CUSTOM-LOCATION-DESCRIPTION')}</p>
          <div className="mt16">
            <Autocomplete
              key={locationAutoCompleteKey}
              options={searchLocationOptions}
              noOptionsText={<p className="text-white">No options available</p>}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={() => true}
              onBlur={handleResetLocationAutocomplete}
              onChange={(_, value) => {
                if (!value) return;
                handleUpdateLocation(value);
                setLocationSearchValue('');
              }}
              loading={searchLocationOptionsIsLoading}
              loadingText={<Loading size="small" />}
              renderOption={(props, option) => (
                <li {...props} key={option.key} style={{ backgroundColor: '#131313' }}>
                  <div>
                    <p>{option.name}</p>
                    <p className="text-faded small mt-6">
                      {option.region}, {option.country_name}
                    </p>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('SOCIAL-ADS.SEARCH-FOR-LOCATIONS')}
                  onChange={(event) => setLocationSearchValue(event.target.value)}
                  sx={{
                    '& .MuiInputLabel-outlined': {
                      borderColor: 'white',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      borderColor: 'white',
                    },
                    '& .MuiAutocomplete-listbox': {
                      maxHeight: 200,
                      overflow: 'auto',
                    },
                  }}
                  InputLabelProps={params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <Icon className="text-faded">search</Icon>,
                  }}
                />
              )}
            />
            {predeterminedLocations === PredeterminedLocations.NONE && (
              <div
                className={`d-flex gap8 flex-wrap ${
                  (formCities.length > 0 || formRegions.length > 0 || formCountries.length > 0) && 'mt16 mb32'
                }`}
              >
                {formCities.length > 0 &&
                  formCities.map((item) => (
                    <Chip
                      key={item.key}
                      label={item.name}
                      data-testid={`social-chip-${item.name}`}
                      deleteIcon={
                        <ButtonComponent isCustom className="p0">
                          <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                            close
                          </Icon>
                        </ButtonComponent>
                      }
                      onDelete={() => handleRemoveLocation(item.key, 'city')}
                    />
                  ))}
                {formRegions.length > 0 &&
                  formRegions.map((item) => (
                    <Chip
                      key={item.key}
                      label={item?.name}
                      data-testid={`social-chip-${item?.name}`}
                      deleteIcon={
                        <ButtonComponent isCustom className="p0">
                          <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                            close
                          </Icon>
                        </ButtonComponent>
                      }
                      onDelete={() => handleRemoveLocation(item.key, 'region')}
                    />
                  ))}
                {formCountries.length > 0 &&
                  formCountries.map((item) => (
                    <Chip
                      key={item}
                      label={CountryNameConverter(item)}
                      data-testid={`social-chip-${item}`}
                      deleteIcon={
                        <ButtonComponent isCustom className="p0">
                          <Icon className="fs-16" data-testid={`social-chip-${item}-delete`}>
                            close
                          </Icon>
                        </ButtonComponent>
                      }
                      onDelete={() => handleRemoveLocation(item, 'country')}
                    />
                  ))}
              </div>
            )}
          </div>
          <div className="mt16">
            <Autocomplete
              key={languageAutoCompleteKey}
              options={searchLanguageOptions}
              noOptionsText={<p className="text-white">No options available</p>}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={() => true}
              onBlur={handleResetLanguageAutocomplete}
              onChange={(_, value) => {
                if (!value) return;
                handleUpdateLanguage(value);
                setLanguageSearchValue('');
              }}
              loading={searchLanguageOptionsIsLoading}
              loadingText={<Loading size="small" />}
              renderOption={(props, option) => (
                <li {...props} key={option.key} style={{ backgroundColor: '#131313' }}>
                  <div>
                    <p>{option.name}</p>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('SOCIAL-ADS.SEARCH-BY-LANGUAGE')}
                  onChange={(event) => setLanguageSearchValue(event.target.value)}
                  sx={{
                    '& .MuiInputLabel-outlined': {
                      borderColor: 'white',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      borderColor: 'white',
                    },
                    '& .MuiAutocomplete-listbox': {
                      maxHeight: 200,
                      overflow: 'auto',
                    },
                  }}
                  InputLabelProps={params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <Icon className="text-faded">search</Icon>,
                  }}
                />
              )}
            />
            <div className="d-flex mt16 gap8 flex-wrap">
              {chosenLanguages.length > 0 &&
                chosenLanguages.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    data-testid={`social-chip-${item.name}`}
                    deleteIcon={<Icon data-testid={`social-chip-${item.name}-delete`}>cancel</Icon>}
                    onDelete={() => handleRemoveLanguage(item)}
                  />
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const MetaInterestsComponent = ({
  predeterminedInterests,
  targetingIsLoading,
}: {
  predeterminedInterests?: MetaAdsInterestsModel[];
  targetingIsLoading: boolean;
}) => {
  const { t } = useTranslation();

  const { accountId } = useAccountContext();
  const [selectedInterests, setSelectedInterests] = useState<MetaAdsInterestsModel[]>([]);

  const [loadingSuggestedInterests, setLoadingSuggestedInterests] = useState<boolean>(false);
  const [suggestedInterestOptions, setSuggestedInterestOptions] = useState<MetaAdsInterestsModel[]>([]);

  const [interestsSearchValue, setInterestsSearchValue] = useState<string>('');
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());

  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formAdAccountId = watch('facebookAdAccount.adAccountId');

  const { recommendedInterests, recommendedInterestsIsLoading } = useMetaRecommendedInterests({
    adAccountId: formAdAccountId,
  });
  const { searchInterestOptions, searchInterestOptionsIsLoading, refetchSearchInterestOptions } =
    useMetaSearchInterestOptions({
      adAccountId: formAdAccountId,
      search: interestsSearchValue,
    });

  const handleResetInterestAutocomplete = useCallback(async () => {
    setInterestsSearchValue('');
    setAutoCompleteKey(uuidv4());
    await refetchSearchInterestOptions(undefined);
  }, [refetchSearchInterestOptions]);

  const getSuggestedInterests = useCallback(async () => {
    setLoadingSuggestedInterests(true);
    try {
      if (!accountId || !formAdAccountId || !selectedInterests.length) return;
      const response = await MetaAPI.getMetaInterestSuggestions({
        accountId,
        adAccountId: formAdAccountId,
        interests: selectedInterests.map((value) => ({ id: value.id, type: value.type })),
      });
      setSuggestedInterestOptions(response.data.data);
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setLoadingSuggestedInterests(false);
    }
  }, [accountId, formAdAccountId, selectedInterests]);

  useEffect(() => {
    if (recommendedInterests && !predeterminedInterests && targetingIsLoading) {
      setSelectedInterests(recommendedInterests);
    }
  }, [getSuggestedInterests, predeterminedInterests, recommendedInterests, setValue, targetingIsLoading]);

  useEffect(() => {
    if (predeterminedInterests && predeterminedInterests.length > 0 && !recommendedInterestsIsLoading) {
      const interests = predeterminedInterests.filter(
        (value: MetaAdsInterestsModel, index: number, self: MetaAdsInterestsModel[]) =>
          index === self.findIndex((t: MetaAdsInterestsModel) => t.id === value.id)
      );
      setSelectedInterests(interests);
    }
  }, [predeterminedInterests, recommendedInterestsIsLoading]);

  useEffect(() => {
    if (interestsSearchValue.length) {
      refetchSearchInterestOptions();
    }
  }, [interestsSearchValue, refetchSearchInterestOptions]);

  const handleUpdateInterests = async (interest: MetaAdsInterestsModel) => {
    const updatedAudiences = [...selectedInterests, interest];
    setSelectedInterests(updatedAudiences);
    await getSuggestedInterests();
    await handleResetInterestAutocomplete();
  };

  const handleDeleteInterest = async (interest: MetaTargetingAudiencesModel) => {
    const updatedAudiences = selectedInterests.filter((value) => value.id !== interest.id);
    setSelectedInterests(updatedAudiences);
    await getSuggestedInterests();
  };

  useEffect(() => {
    if (selectedInterests.length > 0) {
      setValue('ad.targeting.audiences', selectedInterests);
      getSuggestedInterests();
    }
  }, [getSuggestedInterests, selectedInterests, setValue]);

  return (
    <div className="card-inner p16 gap20 text-left">
      <Autocomplete
        key={autoCompleteKey}
        options={searchInterestOptions}
        noOptionsText={<p className="text-white">No options available</p>}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={() => true}
        onBlur={handleResetInterestAutocomplete}
        onChange={(_, value) => {
          if (!value) return;
          handleUpdateInterests(value);
        }}
        loading={searchInterestOptionsIsLoading}
        loadingText={<Loading size="small" />}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ backgroundColor: '#131313' }}>
            <div>
              <p>{option.name}</p>
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('SOCIAL-ADS.SEARCH-FOR-INTERESTS')}
            onChange={(event) => setInterestsSearchValue(event.target.value)}
            sx={{
              '& .MuiInputLabel-outlined': {
                borderColor: 'white',
              },
              '& .MuiInputLabel-shrink': {
                paddingRight: 0,
                borderColor: 'white',
              },
              '& .MuiAutocomplete-listbox': {
                maxHeight: 200,
                overflow: 'auto',
              },
            }}
            InputLabelProps={params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <Icon className="text-faded">search</Icon>,
            }}
          />
        )}
      />
      {recommendedInterestsIsLoading ? (
        <Loading size="small" />
      ) : (
        <div>
          <p className="fw-bold mt16">{t('SOCIAL-ADS.SELECTED-INTEREST-TARGETS')}</p>
          {selectedInterests.length === 0 && <p className="text-faded small mt8">{t('SOCIAL-ADS.NO-INTERESTS')}</p>}
          <div className="d-flex mt16 gap8 flex-wrap">
            {selectedInterests.length > 0 &&
              selectedInterests.map((item) => (
                <Chip
                  key={item.id}
                  label={item.name}
                  data-testid={`social-chip-${item.name}`}
                  deleteIcon={
                    <ButtonComponent isCustom className="p0">
                      <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                        close
                      </Icon>
                    </ButtonComponent>
                  }
                  onDelete={() => handleDeleteInterest(item)}
                />
              ))}
          </div>
        </div>
      )}
      {loadingSuggestedInterests ? (
        <Loading size="small" />
      ) : (
        <div>
          <p className="fw-bold mt16">{t('SOCIAL-ADS.MORE-SUGGESTED-INTEREST-TARGETS')}</p>
          {suggestedInterestOptions.length === 0 && (
            <p className="text-faded small mt8">{t('SOCIAL-ADS.NO-SUGGESTIONS')}</p>
          )}
          <div className="d-flex mt16 gap8 flex-wrap">
            {suggestedInterestOptions.length > 0 &&
              suggestedInterestOptions.map((item) => (
                <Chip
                  className="unselected"
                  key={item.id}
                  label={item.name}
                  data-testid={`social-chip-${item.name}`}
                  onClick={() => handleUpdateInterests(item)}
                  deleteIcon={
                    <ButtonComponent isCustom className="p0">
                      <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                        close
                      </Icon>
                    </ButtonComponent>
                  }
                  onDelete={() =>
                    setSuggestedInterestOptions(
                      suggestedInterestOptions.filter((suggestion) => suggestion.id !== item.id)
                    )
                  }
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MetaAudienceTargeting = ({
  canStep,
  predeterminedLocations,
  setPredeterminedLocations,
}: {
  canStep: (value: boolean) => void;
  predeterminedLocations: PredeterminedLocations;
  setPredeterminedLocations: Dispatch<SetStateAction<PredeterminedLocations>>;
}) => {
  const MIN_DISTANCE = 1;

  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formGenders = watch('ad.targeting.genders');
  const formMinAge = watch('ad.targeting.ageMin');
  const formMaxAge = watch('ad.targeting.ageMax');
  const formAudiences = watch('ad.targeting.audiences');
  const formCountryGroups = watch('ad.targeting.geoLocations.countryGroups');
  const formCountries = watch('ad.targeting.geoLocations.countries');
  const formCities = watch('ad.targeting.geoLocations.cities');
  const formRegions = watch('ad.targeting.geoLocations.regions');

  const { targetingSuggestions, targetingSuggestionsIsLoading } = useMetaTargetingSuggestions();

  const chosenGender = useMemo(() => {
    const genderIndex = formGenders[0] ?? 0;

    return advertGenders.find((item) => item.value === genderMap[genderIndex]) || advertGenders[0];
  }, [formGenders]);

  useEffect(() => {
    canStep(
      !!(
        formGenders &&
        formMinAge &&
        formMaxAge &&
        formAudiences &&
        formAudiences.length > 0 &&
        ((formCountryGroups && formCountryGroups.length > 0) ||
          (formCountries && formCountries.length > 0) ||
          (formCities && formCities.length > 0) ||
          (formRegions && formRegions.length > 0))
      )
    );
  }, [
    canStep,
    formMaxAge,
    formMinAge,
    formAudiences,
    formCities,
    formCountries,
    formCountryGroups,
    formGenders,
    formRegions,
  ]);

  return (
    <AudienceTargeting
      renderGenderComponent={() => (
        <ToggleButtonGroup value={chosenGender.value} className="gender-toggle-group mt16">
          {advertGenders.map((item) => (
            <ToggleButton
              key={item.value}
              value={item.value}
              onClick={() => {
                const genderValue = inverseGenderMap[item.value];
                const genders = genderValue === 0 ? [] : [genderValue];
                setValue('ad.targeting.genders', genders);
              }}
            >
              <div className="d-flex">
                <img src={`/images/icons/gender-${item.icon}.svg`} alt="" />
                <p className="small pt0">{t(item.viewValue)}</p>
              </div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      renderAgeRangeComponent={() => (
        <Slider
          slots={{
            thumb: (props: { children: ReactNode }) => {
              const { children, ...other } = props;
              return (
                <SliderThumb {...other}>
                  {children}
                  <img className="slider-thumb-icon" src="/images/icons/SliderThumb.svg" alt="" />
                </SliderThumb>
              );
            },
          }}
          defaultValue={[formMinAge, formMaxAge]}
          step={1}
          max={65}
          min={18}
          value={[formMinAge, formMaxAge]}
          valueLabelDisplay="on"
          name="budget"
          disableSwap={true}
          onChange={(_, value, activeThumb) => {
            if (!Array.isArray(value)) {
              return;
            }

            if (activeThumb === 0) {
              setValue('ad.targeting.ageMin', Math.min(value[0], formMaxAge - MIN_DISTANCE));
            } else {
              setValue('ad.targeting.ageMax', Math.max(value[1], formMinAge + MIN_DISTANCE));
            }
          }}
        />
      )}
      renderLocationTargetingComponent={() => (
        <MetaLocationComponent
          predeterminedLocations={predeterminedLocations}
          targetingSuggestions={targetingSuggestions?.locations}
          targetingIsLoading={targetingSuggestionsIsLoading}
          setPredeterminedLocations={setPredeterminedLocations}
        />
      )}
      renderInterestsComponent={() => (
        <MetaInterestsComponent
          predeterminedInterests={targetingSuggestions?.interests}
          targetingIsLoading={targetingSuggestionsIsLoading}
        />
      )}
    />
  );
};

export default MetaAudienceTargeting;
